<template>
    <div>Esporta Sodalizi</div>
</template>

<script>
 import { SET_BREADCRUMB } from "@/core/services/store/breadcrumbs.module";

 export default {
     mounted() {
         this.$store.dispatch(SET_BREADCRUMB, [
             { title: "Sodalizi", route: "/sodalizi/index" },
             { title: "Esporta Sodalizi" }
         ]);
     }
 };
</script>
